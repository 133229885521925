<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">流程管理</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >审批管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>流程管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="card-box">
      <div class="screen-box">
        <div class="input-box">
          <div class="title-box">流程名称:</div>
          <el-input placeholder="请输入流程名称" v-model="name"></el-input>
        </div>
        <div class="input-box">
          <div class="title-box">业务表名:</div>
          <el-input placeholder="请输入业务表名" v-model="tableName"></el-input>
        </div>
        <button class="btn-pre" @click="getWorkflows(1)">
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-add" @click="newBuilt" v-if="powers.add">
          <i class="fa ion-plus-round"></i>新建
        </button>
      </div>
      <div class="table-box" v-if="tables">
        <el-table
          :data="tableData"
          border
          height="500"
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
          :empty-text="loading ? '加载中...' : '暂无数据'"
        >
          <el-table-column label="#" width="55">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="WorkName" label="流程名称" width="120">
          </el-table-column>
          <el-table-column prop="WorkTable" label="业务表" width="120">
          </el-table-column>
          <el-table-column prop="WorkTableName" label="业务表名" width="100">
            <template slot-scope="scope">
              <!-- {{ scope.row.WorkTableName | filterName(tables) }} -->
              {{ scope.row.WorkTableName }}
            </template>
          </el-table-column>
          <el-table-column prop="Enable" label="是否启用" width="80">
            <template slot-scope="scope">
              <div class="label-success approval" v-if="scope.row.Enable === 1">
                是
              </div>
              <div class="label-warning approval" v-if="scope.row.Enable === 2">
                否
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="AuditingEdit" label="审核中可编辑" width="120">
            <template slot-scope="scope">
              <div class="label-success approval" v-if="scope.row.Enable === 1">
                是
              </div>
              <div class="label-warning approval" v-if="scope.row.Enable === 2">
                否
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="Remark" label="备注" width="120">
          </el-table-column>
          <el-table-column prop="Creator" label="创建人" width="120">
          </el-table-column>
          <el-table-column prop="ModifyDate" label="更新时间" width="170">
            <template slot-scope="scope">{{
              scope.row.ModifyDate ? scope.row.ModifyDate : scope.row.CreateDate
            }}</template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <i
                class="fa fa-edit"
                v-if="powers.update"
                @click="edit(scope.row)"
              ></i>
              <i
                class="fa fa-trash"
                v-if="powers.delete"
                @click="deleteWorkflows(scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="getWorkflows(1)"
          @current-change="getWorkflows"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :title="dialogType === 1 ? '新建流程' : '编辑流程'"
      :visible.sync="dialogVisible"
      width="90%"
      :before-close="handleClose"
      top="3vh"
    >
      <flow-panel
        ref="flow"
        @close="handleClose"
        @save="handleSave"
      ></flow-panel>
    </el-dialog>
  </div>
</template>

<script>
import { tableData } from "@/utils/tableData";
import FlowPanel from "@/components/ef/panel";
import { getDataB } from "@/components/ef/data_B";
import {
  getWorkflows,
  addWorkflows,
  updateWorkflows,
  deleteWorkflows,
  getTables,
} from "@/api/user";
export default {
  filters: {
    filterName(data, tables) {
      const item = tables.find((item) => {
        return item.key === data;
      });
      return item ? item.value : "";
    },
  },
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      multipleSelection: [],
      dialogType: 1, //1新建2、编辑
      page: 1,
      size: 10,
      total: null,
      name: "",
      tableName: "",
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      tables: null,
      loading: true,
    };
  },
  components: { FlowPanel },
  created() {
    getTables().then((res) => {
      if (res.status === 200) {
        this.tables = res.response;
      }
      this.getWorkflows();
      this.getButtonPower();
    });
  },
  methods: {
    // 返回唯一标识
    getUUID() {
      return Math.random().toString(36).substr(3, 10);
    },
    deleteItem(index) {
      this.messageList.splice(index, 1);
    },
    handleNoticeClose() {
      this.noticeDrawer = false;
    },
    //关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    //保存
    handleSave(data) {
      let rootNode = data.data.nodeList
        .filter((c) => {
          return c.type == "start";
        })
        .map((c) => {
          return {
            StepId: c.id,
            StepName: c.name,
            StepAttrType: c.type,
            ParentId: [""],
            Filters: c.filters,
          };
        });

      for (let index = 0; index < rootNode.length; index++) {
        const node = rootNode[index];
        node.OrderId = index;
        //这里有一节点有多个上级节点的时候数据重复了，比如线束节点
        data.data.lineList
          .filter((c) => {
            return c.from == node.StepId;
          })
          .forEach((c) => {
            let item = data.data.nodeList.find((x, index) => {
              if (x.id == c.to) {
                data.data.nodeList[index].FormEditable =
                  data.ruleForm.example === "EmployeeFlightTicketApplication"
                    ? x.FormEditable
                    : data.ruleForm.example ===
                      "FlightTicketRescheduleApplication"
                    ? x.FormEditable
                    : data.ruleForm.example === "DemobilizationApplication"
                    ? x.FormEditable
                    : false;
              }
              return x.id == c.to;
            });
            let _obj = rootNode.find((x) => {
              return x.StepId === item.id;
            });
            if (_obj) {
              _obj.ParentId.push(node.StepId);
            } else {
              let userId = item.userId ? item.userId.map(String) : null;
              let roleId = item.roleId ? item.roleId.map(String) : null;
              let deptId = item.deptId ? item.deptId.map(String) : null;
              console.log("1111", item);
              if (!userId) {
                userId = [];
              }
              if (!roleId) {
                roleId = [];
              }
              if (!deptId) {
                deptId = [];
              }
              let MainTableSignFields = item.MainTableSignFields
                ? item.MainTableSignFields.join(",")
                : "";
              let SubtableSignFields = item.SubtableSignFields
                ? item.SubtableSignFields.join(",")
                : "";
              let Filters = item.Filters
                ? JSON.parse(JSON.stringify(item.Filters))
                : [];
              let newFilters = [];
              Filters.forEach((cItem) => {
                if (typeof cItem.value === "object") {
                  cItem.value = cItem.value.join(",");
                }
                const newItem = {
                  field: cItem.field,
                  filterType: cItem.filterType,
                  value: cItem.value,
                  filtersData: cItem.filtersData,
                };
                newFilters.push(newItem);
              });
              newFilters = JSON.stringify(newFilters);
              rootNode.push({
                ParentId: [node.StepId], //父级id
                StepId: item.id,
                StepName: item.name,
                StepAttrType:
                  item.type === "start"
                    ? 0
                    : item.type === "end"
                    ? 1
                    : item.type === "node"
                    ? 2
                    : 3, //节点类型.start开始，end结束
                StepTypeId: item.auditType, //审核类型,角色，用户，部门(这里后面考虑同时支持多个角色、用户、部门)
                //审核选择的值角色，用户，部门(这里后面考虑同时支持多个角色、用户、部门)
                StepValue:
                  item.auditType == 1
                    ? userId
                    : item.auditType == 2
                    ? roleId
                    : deptId,
                AuditRefuse: item.auditRefuse, //审核未通过(返回上一节点,流程重新开始,流程结束)
                AuditBack: item.auditBack, //驳回(返回上一节点,流程重新开始,流程结束)
                AuditMethod: item.auditMethod ? 2 : 3, //审批方式(启用会签)
                SendMail: item.SendMail ? 1 : 0, //审核后发送邮件通知：
                FormEditable:
                  data.ruleForm.example === "EmployeeFlightTicketApplication"
                    ? item.FormEditable
                    : data.ruleForm.example ===
                      "FlightTicketRescheduleApplication"
                    ? item.FormEditable
                    : data.ruleForm.example === "DemobilizationApplication"
                    ? item.FormEditable
                    : false,
                Filters: newFilters,
                MainTableSignFields: MainTableSignFields,
                SubtableSignFields: SubtableSignFields,
              });
            }
          });
        // rootNode.push(...data);
      }

      const item = this.tables.find((item) => {
        return item.key === data.ruleForm.example;
      });
      console.log("data.data.data.nodeList", data.data.nodeList);
      const parameter = {
        Id: "",
        Name: data.ruleForm.name,
        WorkTable: data.ruleForm.example,
        WorkTableName: item.value,
        Enable: data.ruleForm.enable,
        LineConfig: JSON.stringify(data.data.lineList),
        NodeConfig: JSON.stringify(data.data.nodeList),
        Weight: data.ruleForm.weight,
        LeaveTypes: data.ruleForm.LeaveTypes,
        Remark: data.ruleForm.notes,
        AuditingEdit: data.ruleForm.editable,
        WorkFlowSteps: rootNode,
        CompanyIDs: data.ruleForm.companyIDs,
      };
      console.log("1111", parameter);
      // return
      if (this.dialogType === 1) {
        addWorkflows(parameter).then((res) => {
          if (res.status === 200) {
            this.$message.success("创建成功");
            this.dialogVisible = false;
            this.getWorkflows();
          }
        });
      }

      if (this.dialogType === 2) {
        parameter.Id = data.ruleForm.id;
        updateWorkflows(parameter).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.dialogVisible = false;
            this.getWorkflows();
          }
        });
      }
    },
    //新建流程
    newBuilt() {
      this.dialogVisible = true;
      this.dialogType = 1;
      let dataA = {
        name: "",
        nodeList: [
          {
            id: "nodeA",
            name: "流程开始",
            type: "start",
            left: "302px",
            top: "61px",
            ico: "el-icon-time",
          },
          {
            id: "nodeB",
            name: "流程结束",
            type: "end",
            left: "302px",
            top: "465px",
            ico: "el-icon-switch-button",
          },
        ],
        lineList: [],
      };
      dataA.nodeList[0].id = this.getUUID();
      dataA.nodeList[1].id = this.getUUID();
      this.$nextTick(() => {
        this.$refs.flow.dataReload(dataA);
        const ruleForm = {
          name: "",
          example: "",
          weight: 1,
          notes: "",
          editable: false,
          enable: true,
          LeaveTypes: [],
        };
        this.$refs.flow.getRuleForm(ruleForm);
      });
    },
    // 编辑
    edit(row) {
      this.dialogVisible = true;
      this.dialogType = 2;
      const multipleSelectionItem = row;
      const newData = {
        name: multipleSelectionItem.WorkName,
        nodeList: JSON.parse(multipleSelectionItem.NodeConfig),
        lineList: JSON.parse(multipleSelectionItem.LineConfig),
      };
      console.log("11111multipleSelectionItem", multipleSelectionItem);
      console.log("11111newData", newData);
      const ruleForm = {
        id: multipleSelectionItem.WorkFlow_Id,
        name: multipleSelectionItem.WorkName,
        example: multipleSelectionItem.WorkTable,
        companyIDs: [],
        weight: multipleSelectionItem.Weight,
        notes: multipleSelectionItem.Remark,
        LeaveTypes: multipleSelectionItem.LeaveTypes
          ? multipleSelectionItem.LeaveTypes.split(",").map(Number)
          : [],
        editable: multipleSelectionItem.AuditingEdit === 1 ? true : false,
        enable: multipleSelectionItem.Enable === 1 ? true : false,
      };
      if (multipleSelectionItem.CompanyIDs) {
        ruleForm.companyIDs =
          multipleSelectionItem.CompanyIDs.split(",").map(Number);
      }
      console.log("11111", ruleForm);

      this.$nextTick(() => {
        this.$refs.flow.dataReload(newData);
        this.$refs.flow.getRuleForm(ruleForm);
      });
    },
    //获取流程管理列表
    getWorkflows(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      const data = {
        page: this.page,
        size: this.size,
        name: this.name,
        tableName: this.tableName,
      };
      getWorkflows(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
        }
        this.loading = false;
      });
    },
    deleteWorkflows(row) {
      let data = { id: "" };
      data.id = row.WorkFlow_Id;
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteWorkflows(data).then((res) => {
          //
          if (res.status === 200) {
            this.$message.success("删除成功");
            this.getWorkflows();
          }
        });
      });
    },
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 24px 0;
    .screen-box {
      display: flex;
      align-items: center;
      padding: 0 12px;
      .el-input {
        width: 150px;
        height: 34px;
        margin-right: 24px;
      }
      button {
        margin-left: 24px;
        border: none;
        padding: none;
        padding: 6px 12px;
        border-radius: 3px;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
        .fa {
          color: #fff;
          margin: 0;
          margin-right: 5px;
        }
      }
      .btn-pre {
        background-color: #3f729b;
      }
      .btn-pre:hover {
        background-color: #305777;
      }
      .btn-add {
        background-color: #3c8dbc;
      }
      .btn-add:hover {
        background-color: #367fa9;
      }
      .input-box {
        display: flex;
        align-items: center;
        height: 34px;
        .title-box {
          padding: 0 12px;
          border: 1px solid #d2d6de;
          line-height: 32px;
          border-right: none;
          color: #555;
          font-size: 14px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 65px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    font-weight: normal;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  min-height: 600px;
  border-top: 2px solid #e6e6e6;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
</style>